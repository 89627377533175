import {CalendarDates, EventsDates, StatusFilter} from '@wix/events-types'
import {addQueryParams, stringifyParams} from '@wix/panda-js-utils'
import {EVENT_FILTER_TYPE, EVENT_SORT_ORDER, RECURRING_FILTER} from '@wix/wix-events-commons-statics'
import {ControllerParams, IUser} from '@wix/yoshi-flow-editor'
import {UPDATE_SITE_SETTINGS} from '../../../../commons/actions/site-settings'
import {SiteSettingsSettings} from '../../../../commons/types/state'
import {Api as BaseApi} from '../../../../commons/utils/api'
import {getWidgetData} from '../../shared-api'
import {LOAD_MEMBERS_FOR_EVENTS, PROMPT_LOGIN} from '../actions/members'

export class Api extends BaseApi {
  constructor(controllerParams: ControllerParams) {
    super(controllerParams)

    this.registrar = {
      [UPDATE_SITE_SETTINGS.NAME]: this.updateSiteSettings,
      [PROMPT_LOGIN.NAME]: this.promptLogin,
      [LOAD_MEMBERS_FOR_EVENTS.NAME]: this.getGuestLists,
    }
  }

  getWidgetData = getWidgetData(this.api)

  loadEvents = (args: LoadEventsParams): Promise<LoadEventsResponse> =>
    this.api.get(
      addQueryParams(
        '/web/paginated-events/viewer',
        stringifyParams({
          ...args,
          draft: this.viewMode !== 'site',
          compId: this.compId,
        }),
      ),
    )

  loadCalendarEvents = (args: LoadCalendarEventsParams): Promise<LoadCalendarEventsResponse> =>
    this.api.get(
      addQueryParams(
        '/web/calendar-events/viewer',
        stringifyParams({
          ...args,
          draft: this.viewMode !== 'site',
          compId: this.compId,
          tz: this.tz,
        }),
      ),
    )

  updateSiteSettings = (settings: SiteSettingsSettings) => {
    return this.api.put(`/web/site-settings`, {settings})
  }

  getGuestLists = (eventIds: string[]): Promise<GuestLists> =>
    this.api.get(`/web/members?eventIds=${JSON.stringify(eventIds)}`)

  promptLogin = (lang: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        await this.controller.wixCodeApi.user.promptLogin({
          mode: 'login',
          lang,
        })
        resolve(true)
      } catch (e) {
        if (typeof e !== 'string') {
          reject(e)
          return
        }
        resolve(false)
      }
    })
  }

  onLogin = (handler: LoginHandler) => {
    this.controller.wixCodeApi.user.onLogin(handler)
  }
}

export type LoginHandler = (user: IUser) => void

interface LoadEventsParams {
  offset: number
  filter: StatusFilter
  byEventId: boolean
  members: boolean
  paidPlans: boolean
  locale: string
  categoryId: string[]
  recurringFilter?: RECURRING_FILTER
  filterType: EVENT_FILTER_TYPE
  sortOrder: EVENT_SORT_ORDER
  limit?: number
}

export interface LoadEventsResponse {
  events: ExtendedEvent[]
  dates: {events: EventsDates}
  hasMore: boolean
  total: number
}

interface LoadCalendarEventsParams {
  referenceDate: string
  filter: StatusFilter
  byEventId: boolean
  members: boolean
  paidPlans: boolean
  locale: string
  categoryId: string[]
  showcase: boolean
  filterType: EVENT_FILTER_TYPE
  sortOrder: EVENT_SORT_ORDER
  multiDayExperimentEnabled?: boolean
  expandBounds?: boolean
}

export interface LoadCalendarEventsResponse {
  events: ExtendedEvent[]
  dates: {events: EventsDates; calendar: CalendarDates}
}
